<template>
    <div id="mian">
        <div class="header">
            <span @click="$router.push('/organization_jghz')">机构汇总</span>
            <span @click="$router.push('/channel_qdhz')">渠道汇总</span>
            <span @click="$router.push('/commercial_shhz')">商户汇总</span>
            <span id="fzcolor" >服务商汇总</span>
            <span @click="$router.push('/agent_dlshz')">代理商汇总</span>
        </div>
        <!-- 批量订单查询 -->
        <div>
            <!-- 上面表单框 -->
            <div class="con_from">
                <p>
                    <i>服务商名称</i>
                    <el-input v-model="input" style="width:200px;height:36px" suffix-icon="el-icon-search"	 placeholder="服务商名称/服务商编号"></el-input>
                    <i class="jianju">结算日期</i>
                    <el-date-picker 
                    style="width:200px;height:36px;"
                    v-model="value1"
                    type="datetime"
                    placeholder="选择时间">
                    </el-date-picker>
                    <span class="jianju2">-</span>
                    <el-date-picker
                    style="width:200px;height:36px"
                    v-model="value2"
                    type="datetime"
                    placeholder="选择时间">
                    </el-date-picker>
                    <el-button class="searchBt">查询</el-button>
                </p>
            </div>
            <!-- 中间交易账目 -->
            <div class="jiaoyi">
                <div class="jiaoyi_box">
                    <p><i>交易总数</i></p>
                    <p><b>14235</b></p>
                </div>
                <div class="jiaoyi_box">
                    <p><i>交易金额</i></p>
                    <p><b>14235</b></p>
                </div>
                <div class="jiaoyi_box">
                    <p><i>退款总数</i></p>
                    <p><b>14235</b></p>
                </div>
                <div class="jiaoyi_box">
                    <p><i>退款金额</i></p>
                    <p><b>14235</b></p>
                </div>
                <div class="jiaoyi_box">
                    <p><i>分润金额</i></p>
                    <p><b>14235</b></p>
                </div>
            </div>
            <div class="tab1">
                <table class="tab">
                    <tr class="tab_title" >
                        <td><p>结算日期</p> <p>交易日期</p></td>
                        <td ><p>服务商名称</p></td>
                        <td ><p>服务商编号</p></td>
                        <td ><p>交易笔数(笔)</p></td>
                        <td><p>交易金额(元)</p></td>
                        <td ><p>退款笔数(笔)</p></td>
                        <td ><p>退款金额(元)</p></td>
                        <td ><p>分润金额(元)</p></td>
                        <td style="ppadding-right: 10px;"><p>操作</p></td>
                    </tr>
                    <tr>
                        <td><p>2019-01-12 15:51:15</p><p>2019-01-12 15:51:15</p> </td>
                        <td><p>深圳有数互动科技有限公司</p></td>
                        <td><p>12345678901234567</p> <p>0000678901234567</p></td>
                        <td><p>-</p> <p>-</p></td>
                        <td ><p>微信扫码支付</p></td>
                        <td><p>支付成功</p></td>
                        <td><p>55.1</p></td>
                        <td><p>否</p></td>
                        <td style="padding-right: 10px;"><p><i class="lianjie">详情</i></p></td>
                    </tr>
                </table>
            </div>  
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            value1:"",
            value2:"",
            crumbs:"批量查询",

        }
    },
    methods:{
        //菜单切换
		navClick(data){
			this.crumbs=data
		},
    }
}
</script>
<style scoped>
#fzcolor{
    font-size:14px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(72,184,182,1);
    border-bottom:3px solid  rgba(72,184,182,1);
}
.active{
    color:rgba(72,184,182,1);
    border-bottom:3px solid #48B8B6; 
}
.lianjie{
    color: #008AFF;
}
.tab tr td p{
    width: 100%;
    text-align: center;
    font-size:14px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(102,102,102,1);
}
.tab_title{
    background:rgba(244,245,246,1);
    border-top:1px solid rgba(220,224,230,1); 
}
.tab1 .tab tr{
    padding-left:20px; 
    height: 60px;
    border-bottom:1px solid rgba(220,224,230,1); 
}
.tab{
    border-collapse:collapse; 
    width: 100%;
    background: #fff;
    border-bottom:1px solid rgba(220,224,230,1); 
}
.tab1{
    width: 100%;
    margin-top:20px; 
}
.jiaoyi_box p b{
    font-size:24px;
    font-family:PingFang SC;
    font-weight:600;
    color:rgba(72,184,182,1);
}
.jiaoyi_box p i{
    font-size:12px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(153,153,153,1);
}
.jiaoyi_box p{
    width: 100%;
    text-align: center;
    padding-top:13px; 
}
.jiaoyi{
    width: 100%;
    height: 90px;
    position: relative;
    margin-top:20px;
    display: flex;
    justify-content:space-between;
}
.jiaoyi_box{
    float: left;
    width: 15.5%;
    height: 90px;
    background:rgba(255,255,255,1);
    box-shadow:0px 2px 9px 0px rgba(0, 0, 0, 0.05);
    border-radius:5px;
}
.jianju{
    
    padding-left:110px; 
}
.con_from p i{
    padding-right:12px; 
    font-size:14px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(0,0,0,1);
}
.con_from{
    width: 100%;
    position: relative;
    overflow: hidden;

}
.con_from p{
    padding-top:20px;
    padding-left: 15px;
}
.header span{
    display: inline-block;
    height: 100%;
    width: 100px;
    text-align: center;
    font-size:14px;
    font-family:Microsoft YaHei;
    font-weight:400;
}
.header{
    line-height: 40px;
    width: 100%;
    height: 40px;
    border-bottom:1px solid  rgba(220,224,230,1);
    position: relative;
    top: 0;
}
#mian{
    padding-left:20px;
    padding-right:21px;  
    width: 96.5%;
    height: 100%;
    background:rgba(255,255,255,1);
    overflow-y:auto;
    overflow-x:hidden; 
}
  .searchBt {
    float: right;
    background: #48B8B6;
    border-color: #48B8B6;
    margin-left: 20px;
    width: 90px;
    height: 36px;
    padding: 0;
    color: #FFFFFF;
  }
  .jianju2{
    display: inline-block;
    width: 51px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    vertical-align: middle;
  }
</style>